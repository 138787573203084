import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

class SwiperManager {
    constructor(el, lazy) {
        //console.log('inizializzo swiper su', this.el);
        if (lazy == false) {
            this.el = el;
            this.init();
        } else {
            this.wait(el);
        }
    }

    init() {
        const cfgwrapper = this.el.parent().children(".swiperconfig");
        const cfgoverride = this.el
            .closest("[data-overrideswiper]")
            .children(".swiperconfig");
        let minwidth =
            cfgoverride.attr("data-minwidth") ||
            cfgwrapper.attr("data-minwidth");
        let maxwidth =
            cfgoverride.attr("data-maxwidth") ||
            cfgwrapper.attr("data-maxwidth");

        let active = true;
        let r = "";

        if (minwidth && minwidth > $(window).width()) {
            active = false;
            r = "too little";
        }

        if (maxwidth && maxwidth < $(window).width()) {
            active = false;
            r = "too big";
        }

        if (active) {
            this.defaultconfig = {
                slidesPerView: 1,
                spaceBetween: 30,
            };

            try {
                this.localconfig =
                    cfgwrapper.length > 0 ? JSON.parse(cfgwrapper.text()) : {};
            } catch (e) {
                console.error(
                    "Errore nel parsing del json swiper local contenuto per",
                    this.el
                );
                console.error(
                    "Errore nel parsing del json swiper local contenuto in",
                    cfgwrapper
                );
                console.error(
                    "Errore nel parsing del json swiper local",
                    cfgwrapper.text()
                );
                this.localconfig = {};
            }

            try {
                this.overriddenconfig =
                    cfgoverride.length > 0
                        ? JSON.parse(cfgoverride.text())
                        : {};
            } catch (e) {
                console.error(
                    "Errore nel parsing del json swiper override",
                    cfgwrapper.text()
                );
                this.overriddenconfig = {};
            }

            this.config = Object.assign(
                this.defaultconfig,
                this.localconfig,
                this.overriddenconfig
            );

            if (
                this.config.hasOwnProperty("pagination") &&
                this.config.pagination.hasOwnProperty("renderBullet")
            ) {
                const myf = window[this.config.pagination.renderBullet];
                const iscallable = typeof myf === "function";

                if (iscallable) {
                    this.config.pagination.el = ".swiper-pagination";
                    this.config.pagination.clickable = true;
                    this.config.pagination.type = "bullets";
                    this.config.pagination.renderBullet = myf;
                }
            }

            this.activate();
        } else {
            //console.log('non attivo lo swiper ('+r+') su ', this.el);
        }
    }

    activate() {
        //console.log('attivo swiper', this.config);
        this.swiper = new Swiper(this.el.get(0), this.config);

        if (this.swiper.params.hasOwnProperty('autoplay')) {
            $(this.swiper.el).parent().addClass("autoplay_" + this.swiper.params.autoplay.delay);

            this.swiper.on("autoplayStop", function () {
                $(this.el).parent().removeClass("autoplay_" + this.params.autoplay.delay);
            });
    
            this.swiper.on("autoplay", function () {
                $(this.el).parent().removeClass("autoplay_" + this.params.autoplay.delay);
    
                setTimeout(
                    function () {
                        $(this.el)
                            .parent()
                            .addClass("autoplay_" + this.params.autoplay.delay);
                    }.bind(this),
                    200
                );
            });
        }
    }

    wait(el) {
        const config = { childList: true, subtree: true };

        const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                let el = $(mutation.target).find(".swiper");
                if (el.length) {
                    this.el = el;
                    this.init();
                }
            }
        });

        observer.observe(el.get(0), config);
    }
}

export default SwiperManager;
