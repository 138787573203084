
require('bootstrap');

import $ from "jquery";
window.$ = window.jQuery = $;

require('./libs/Utils');

import SwiperManager from './libs/SwiperManager.js';
import StepManager from './libs/StepManager.js';
import TableManager from './libs/TableManager.js';
import UXManager from './libs/UXManager.js';
import InfocarDataManager from './libs/InfocarDataManager.js';
import { Dropzone } from "dropzone";
import { Select2 } from "select2";

jQuery(function () {

    window.uxm = new UXManager();
    window.tbm = new TableManager();
    window.idm = new InfocarDataManager();

    // faccio partire i '.component .swiper' non asincroni
    $('.component.slider > .swiper').each(function () {
        const sm = new SwiperManager($(this), false);
    });
});