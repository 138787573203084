
class InfocarDataManager {
    constructor() {
        this.addListeners();
    }

    addListeners() {
        $('select.infocar.make').on("change", function (e, triggered) {
            // se l'evento è un trigger, non facciamo nulla
            if (triggered) return;

            $('input[name="make"]').val($(this).find('option:selected').text());
            $('select.infocar.model').empty().trigger("change", [true]);
            $('select.infocar.setup').empty().trigger("change", [true]).prop('disabled', true);

            const options = {
                type: "GET",
                dataType: "json",
                url: '/infocar/models/'+$(this).val(),
                context: this,
                success: function (j) {
                    let option = new Option('Select', '', false, true);
                    $('select.infocar.model').append(option);
                    for(let i = 0; i < j.length; i ++) {
                        option = new Option(j[i].name, j[i].id, false, false);
                        $('select.infocar.model').append(option);
                    }
                    $('select.infocar.model').prop('disabled', false).trigger("change", [true]);
                },
                error: function (j) {
                    console.log('error', j);
                }
            };

            $.ajax(options);
        });

        $('select.infocar.model').on("change", function (e, triggered) {
            // se l'evento è un trigger, non facciamo nulla
            if (triggered) return;

            $('input[name="model"]').val($(this).find('option:selected').text());
            $('select.infocar.setup').empty().trigger("change", [true]);

            const options = {
                type: "GET",
                dataType: "json",
                url: '/infocar/setups/'+$(this).val(),
                context: this,
                success: function (j) {
                    let option = new Option('Select', '', false, true);
                    $('select.infocar.setup').append(option);
                    for(let i = 0; i < j.length; i ++) {
                        let option = new Option(j[i].name, j[i].id, false, false);
                        $('select.infocar.setup').append(option);
                    }
                    $('select.infocar.setup').prop('disabled', false).trigger("change", [true]);
                },
                error: function (j) {
                    console.log('error', j);
                }
            };

            $.ajax(options);
            
        });

        $('select.infocar.setup').on("change", function (e, triggered) {
            // se l'evento è un trigger, non facciamo nulla
            if (triggered) return;

            $('input[name="setup"]').val($(this).find('option:selected').text());

            const options = {
                type: "GET",
                dataType: "json",
                url: '/infocar/technicalData/'+$(this).val(),
                context: this,
                success: function (j) {
                    console.log('success', j);

                    $('input[name="horsepower"]').val(j.MotorePrestazioni[0].PotenzaCV).prop('readonly', true);
                    $('input[name="kW"]').val(j.MotorePrestazioni[0].PotenzaKW).prop('readonly', true);
                    $('select[name="powersupply"]').val(j.MotorePrestazioni[0].Motore.Combustibile).attr("style", "pointer-events: none;background: #e9ecef;");
                    $('select[name="traction"]').val(j.MotorePrestazioni[0].Trazione).attr("style", "pointer-events: none;background: #e9ecef;");
                    $('input[name="displacement"]').val(j.MotorePrestazioni[0].Motore.Cilindrata).prop('readonly', true);
                    $('input[name="seats"]').val(j.DatiBase.NumeroPosti).prop('readonly', true);
                    $('input[name="doors"]').val(j.DatiBase.NumeroPorte).prop('readonly', true);
                    $('input[name="emissionstandard"]').val(j.DatiBase.Normativa).prop('readonly', true);
                    $('input[name="fuelconsumption"]').val(j.MotorePrestazioni[0].Consumo3).prop('readonly', true);
                    $('input[name="emission"]').val(j.MotorePrestazioni[0].Emissione3).prop('readonly', true);
                    $('input[name="acceleration0100"]').val(j.MotorePrestazioni[0].Accelerazione0100).prop('readonly', true);

                    let gearbox;
                    if ((j.MotorePrestazioni[0].Cambio).indexOf('automatico') >= 0) gearbox = 'automatic';
                    else if ((j.MotorePrestazioni[0].Cambio).indexOf('diretta') >= 0) gearbox = 'direct';
                    else if ((j.MotorePrestazioni[0].Cambio).indexOf('sequenziale') >= 0) gearbox = 'semi-automatic';
                    else gearbox = 'manual';
                    
                    $('select[name="gearbox"]').val(gearbox).attr("style", "pointer-events: none;background: #e9ecef;");
                },
                error: function (j) {
                    console.log('error', j);
                }
            };

            $.ajax(options);
        });
    }
}

export default InfocarDataManager;