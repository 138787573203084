class UXManager {
    constructor() {
        this.addListeners();
    }

    addListeners() {
        if ($(window).width() < 768) {
            const nav = $("header").find(".nav");
            nav.addClass("listContainer");
            const navbar = nav.children(".navbar");
            navbar.addClass("menu_list");
            nav.append('<div class="hide_menu_list listing hidden"></div>');
            nav.children(".hide_menu_list").append(navbar);
        }

        // attivo i Select2
        $('select.select2').each(function () {
            $(this).select2();
        })

        // attivo la tendina di navigazione utente
        $("header")
            .find(".listContainer")
            .each(function () {
                $(this).on("click", function () {
                    var element = $(this).find('[class*="listing"]');
                    var isOpen = $(element).is(":visible");

                    // Close all open user menus
                    $('header .listContainer [class*="listing"]').hide();

                    // Toggle the clicked user menu
                    if (!isOpen) {
                        $(element).show();
                    }
                });
            });

        $('input[type="submit"]').on("click", function () {
            let f = $(this).closest("form");
            let fields = f.find("input, textarea, select");

            let haserrors = false;
            fields.each(function (i, field) {
                let fhaserrors = !field.reportValidity();
                if (fhaserrors) haserrors = true;
            });

            if (!haserrors) {
                $(this).prop("disabled", true);
                f.submit();
            }
        });

        // 
        $('[data-action="resetpassword"').on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();

            const options = {
                type: "GET",
                dataType: "json",
                url: '/user/resetpassword',
                context: this
            };

            $.ajax(options).then(
                function (j) {
                    console.log('success', j);
                    $(this).parent().html($(this).attr('data-message-success'));
                },
                function (j) {
                    console.log('error', j);
                }
            );
            
        })

        $(".component.page")
            .find(".image-container")
            .on("click", function () {
                let container = $(".zoomedimage");
                if ($(".zoomedimage").length == 0) {
                    $("body")
                        .append(
                            '<div class="zoomedimage component offers page"></div>'
                        )
                        .css("overflowY", "hidden");
                    container = $(".zoomedimage");
                }
                container
                    .css("backgroundImage", $(this).css("backgroundImage"))
                    .attr("data-rel", $(this).attr("data-id"))
                    .show();
                container.html(
                    $("section.component.page")
                        .find(".detail")
                        .prop("outerHTML")
                );
            });

        $(document).on("click", ".zoomedimage", function () {
            $("body").css("overflowY", "scroll");
            $(this).hide();
        });

        $("body").on("keydown", function (e) {
            const container = $(".zoomedimage");
            if (container.length == 0) return;

            let currid = container.attr("data-rel");
            let action;

            switch (e.keyCode || e.which) {
                case 37:
                    currid--;
                    if (currid < 0) currid = 15;
                    action = "move";
                    break;
                case 39:
                    currid++;
                    if (currid > 15) currid = 0;
                    action = "move";
                    break;
                case 27:
                    action = "close";
                    break;
            }

            if (action == "move") {
                const ref = $('.image-container[data-id="' + currid + '"]');
                container
                    .css("backgroundImage", $(ref).css("backgroundImage"))
                    .attr("data-rel", $(ref).attr("data-id"))
                    .show();
            } else if (action == "close") {
                container.trigger("click");
            }
        });

        var div = $(".tac_accepted");

        div.each(function () {
            var textarea = $(this);
        
            // Troviamo i div che contengono le checkbox che vogliamo abilitare
            var checkboxContainers = $(".tac_accept");
            // Troviamo tutte le checkbox dentro questi container
            var checkboxes = checkboxContainers.find("input[type='checkbox']");
        
            // Monitoriamo l'evento scroll sulla textarea
            textarea.on("scroll", function () {
                // Controlliamo se l'utente ha raggiunto la fine della textarea
                var padding = 30; // La quantità di padding da sottrarre
                if (
                    textarea.scrollTop() + textarea.innerHeight() >=
                    textarea[0].scrollHeight - (2 * padding)
                ) {
                    // Se l'utente ha raggiunto la fine, abilitiamo tutte le checkbox
                    checkboxes.prop("disabled", false);
                }
            });
        });
        

        function adjustStepsVisibility() {
            if (typeof creationSteps === "undefined") return;
            var windowWidth = $(window).width();
            var activeStepName = $("li.active").data("step");
            var activeStepIndex = creationSteps.indexOf(activeStepName);

            $("li[data-step]").each(function () {
                var stepName = $(this).data("step");
                var stepIndex = creationSteps.indexOf(stepName);

                if (windowWidth <= 767) {
                    if (
                        stepIndex == activeStepIndex ||
                        stepIndex == activeStepIndex - 1 ||
                        stepIndex == activeStepIndex + 1
                    ) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                } else {
                    $(this).show();
                }
            });
        }

        // Chiama la funzione quando la pagina viene caricata
        adjustStepsVisibility();

        // Chiama la funzione quando la dimensione della finestra cambia
        $(window).resize(adjustStepsVisibility);

        const adjustNavItems = () => {
            if ($(window).width() <= 767) {
                var navDropdownItems = $(".nav-dropdown-items").detach();
                navDropdownItems.addClass("menu_list"); // aggiunge le nuove classi
                $(".navbar.menu_list").after(navDropdownItems);
            }
        };

        // Chiama la funzione quando la pagina viene caricata
        adjustNavItems();

        // Chiama la funzione quando la dimensione della finestra cambia
        $(window).resize(adjustNavItems);
    }
}
export default UXManager;
